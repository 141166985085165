.container-article {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #f9f9f9;
}
.title-article {
  text-align: center;
  margin-bottom: 15px;
  font: 1em "Roboto", sans-serif;
  color: #000 !important;
}
.content-article {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.image-article{
  width: 40%; margin-left: 150px;
  margin-bottom: 50px;
  height: auto;
  border-radius: 20px;
}
.text-article {
  width: 40%;
  padding: 40px;
  text-align: "justify";
  display: "flex";
  flex-direction: "column";
  justify-content: "space-between";
}
.text-article-left {
  width: 35%;
  padding: 40px;
  margin-left: 150px;
  text-align: "justify";
  display: "flex";
  flex-direction: "column";
  justify-content: "space-between";
}
.button-article {
  padding: "10px 15px";
  background-color: "#007bff";
  color: "#fff";
  border: "none";
  border-radius: "5px";
  cursor: "pointer";
  align-self: "flex-start",
}

@media (max-width: 768px) {
  .content-article {
    width: 80% !important;
    flex-direction: column;
  }
  .image-article{
    width: 90%;
    margin-left: 0;
    margin-bottom: 0;
  }
  .text-article {
    width: 90%;
    margin-top: 0;
    text-align: "justify",
  }
  .text-article-left {
    margin-left: 0;
    width: 100%;
  }
  .imageGrid {
    grid-template-columns: 1fr !important; /* Una sola columna en móviles */
    grid-template-rows: auto !important;   /* Filas automáticas */
    margin: 0 !important;
  }

  .leftSide {
    margin: 0 !important;
    grid-template-rows: auto;
    grid-row: auto; /* Restablecer el span de fila en móviles */
  }
}
/* CSS */
.button-33 {
  background-color: #c2fbd7;
  border-radius: 100px;
  box-shadow: rgba(44, 187, 99, .2) 0 -25px 18px -14px inset,rgba(44, 187, 99, .15) 0 1px 2px,rgba(44, 187, 99, .15) 0 2px 4px,rgba(44, 187, 99, .15) 0 4px 8px,rgba(44, 187, 99, .15) 0 8px 16px,rgba(44, 187, 99, .15) 0 16px 32px;
  color: green;
  cursor: pointer;
  display: inline-block;
  font-family: CerebriSans-Regular,-apple-system,system-ui,Roboto,sans-serif;
  padding: 7px 20px;
  text-align: center;
  text-decoration: none;
  transition: all 250ms;
  border: 0;
  font-size: 16px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  margin-top: 50px;
}

.button-33:hover {
  box-shadow: rgba(44,187,99,.35) 0 -25px 18px -14px inset,rgba(44,187,99,.25) 0 1px 2px,rgba(44,187,99,.25) 0 2px 4px,rgba(44,187,99,.25) 0 4px 8px,rgba(44,187,99,.25) 0 8px 16px,rgba(44,187,99,.25) 0 16px 32px;
  transform: scale(1.05);
}
.highlight {
  color: #0077cc;
  font-weight: 600;
}
.imageGrid {
  display: grid;
  padding: 10px;
  /* grid-template-columns: 1fr 1fr; /* Dos columnas */
  grid-template-columns: 1fr; /* Dos columnas */
  /* grid-template-rows: 1fr 1fr;    /* Dos filas */
  grid-template-rows: 1fr;    /* Dos filas */
  gap: 10px;                      /* Espacio entre imágenes */
  width: 100%;                    /* Ancho completo del contenedor */
  max-width: 800px;               /* Ancho máximo para evitar que se estire demasiado */
  margin: 0 auto;                
  aspect-ratio: 1 / 1;
}

.leftSide {
  grid-row: span 2; /* La imagen izquierda ocupa dos filas */
  margin-top: 80px;
}

.PM{
  width: 100%;
  height: auto;
  border-radius: 20px;
  object-fit: cover;
}